<template>
  <div>
    <div class="p-pegas" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="d-flex w-100" style="justify-content: space-between;  align-items: center;" >
        <div class="caz-blocks-sarcho-title pt-3 pb-3 z-i pl-3  ">
          <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">{{$t("message.report_card")}}</div>
          <div class="block-sarche w90__mobile">
              <div class="header__search my__header__search mobile__column d-flex">
                  <select-staff
                      v-model="filterForm.staff_id"
                      :class="mode ? 'input__day' : 'input__night'"
                      class="journal__mobile"
                      :id="filterForm.staff_id"
                  ></select-staff>
                  <select-branch
                      :id="filterForm.branch_id"
                      class="journal__mobile"
                      :class="mode ? 'input__day' : 'input__night'"
                      v-model="filterForm.branch_id"
                  ></select-branch>
                  <el-date-picker
                      v-model="filterForm.date"
                      :size="'small'"
                      type="month"
                      format="MMMM yyyy"
                      value-format="dd.MM.yyyy"
                      class="journal__mobile"
                      :class="mode ? 'input__day' : 'input__night'"
                      placeholder="Pick a month"
                      @change="triggerChanges"
                      :clearable="false"
                      :picker-options="pickerOptions">
                  </el-date-picker>
              </div> 
          </div>
        </div>
        <div v-loading="loadingData"> 
          <export-excel
            v-if="list.length"
            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
            class="btn excel_btn"
            :data="excelData"
            worksheet="My Worksheet"
            name="Табель.xls"
            :fields="excel_fields"
            :excelStyles="excelStyles"
            style="display: flex; float:none;
            align-items: center;
            margin-right: 10px;"
            @fetch="controlExcelData()"
          >
            <el-button size="mini">
              <i class="el-icon-document-delete"></i> {{$t("message.excel")}}
            </el-button>
          </export-excel>
        </div>
      </div>
      <div class="d-flex journal__mobile m-3 mt-0">
          <div class="coKvadrat" :class="mode ? 'content__titleday' : 'content__titlenight'">
          <span class="tabelColor2"></span>
          <b class="attendance__text" :class="mode ? 'text' : 'textnight'">{{$t("message.unrevealed")}}</b>
          </div>
          <div :class="mode ? 'text' : 'textnight'" class="coKvadrat">
          <span  class="tabelColor3"></span>
          <b class="attendance__text" :class="mode ? 'text' : 'textnight'">{{$t("message.manual")}}</b>
          </div>
          <div :class="mode ? 'text' : 'textnight'" class="coKvadrat">
              <span  class="tabelColor5"></span>
              <b class="attendance__text" :class="mode ? 'text' : 'textnight'">Успешный </b>
          </div>
          <div :class="mode ? 'text' : 'textnight'" class="coKvadrat">
              <span  class="tabelColor1"></span>
              <b class="attendance__text" :class="mode ? 'text' : 'textnight'">{{$t("Отработанные часы")}}</b>
          </div>
          <div :class="mode ? 'text' : 'textnight'" class="coKvadrat">
              <span  class="tabelColor4"></span>
              <b class="attendance__text" :class="mode ? 'text' : 'textnight'">{{$t("Дистанционная или Дополнительная")}}</b>
          </div>
          <div :class="mode ? 'text' : 'textnight'" class="coKvadrat">
            <span  class="wikend-day"></span>
            <b class="attendance__text" :class="mode ? 'text' : 'textnight'">{{$t("Праздничный день")}}</b>
          </div>
          <div :class="mode ? 'text' : 'textnight'" class="coKvadrat">
            <span  class="business_trip"></span>
            <b class="attendance__text" :class="mode ? 'text' : 'textnight'">{{$t("message.business_trip")}}</b>
          </div>
          <div :class="mode ? 'text' : 'textnight'" class="coKvadrat">
            <span  class="in_vacation"></span>
            <b class="attendance__text" :class="mode ? 'text' : 'textnight'">{{$t("message.in_vacation")}}</b>
          </div>
      </div>
      <div class="tableScrol">
          <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code vertical-align-c"
          v-loading="loadingData">
              <thead class="sticky-thead">
                  <tr>
                      <td class="sticiy_22 z-i-4" >
                          <div class="sticiyDiv">~</div>
                      </td>
                      <td class="sticiy_22  z-i-4">
                          <div class="sticiyDiv">т/р</div>
                      </td>
                      <td class="sticiy_22 w-t-150  z-i-4">
                          <div class="sticiyDiv ">Ф.И.Ш.</div>
                      </td>
                      <td class="w-t-100">
                          <div class="sticiyDiv ">оклад</div>
                      </td>
                      <td class="w-t-150">
                          <div class="sticiyDiv">Лавозим</div>
                      </td>
                      <td>
                          <div class="sticiyDiv vertical-rl-css">иш куни</div>
                      </td>
                      <td>
                          <div class="sticiyDiv vertical-rl-css">иш  соати</div>
                      </td>
                      <td>
                          <div class="sticiyDiv vertical-rl-css">стандарт иш вакти</div>
                      </td>
                      <td>
                          <div class="sticiyDiv vertical-rl-css">Общий рабочий час</div>
                      </td>
                      <td>
                        <div class="sticiyDiv vertical-rl-css">Дистанционная или Дополнительная</div>
                    </td>
                      <td class="month_day" :class="mode ? 'content__titleday' : 'content__titlenight'" v-for="(day, index) in range.first" :key="'first' + index" >
                        <div class="sticiyDiv vertical-rl-css">{{ getDay(day, filterForm.date) }}</div>
                      </td>
                      <td>
                          <div class="sticiyDiv vertical-rl-css">соат нархи </div>
                      </td>
                      <td>
                          <div class="sticiyDiv ">Норма буйича <br> иш хаки </div>
                      </td>
                      <td>
                          <div class="sticiyDiv vertical-rl-css">транспорт харажатлари ва рагбадлантириш </div>
                      </td>
                      <td>
                          <div class="sticiyDiv ">Жами <br> туланиш керак</div>
                      </td>
                      <td>
                          <div class="sticiyDiv vertical-rl-css">Жарима</div>
                      </td>
                      <td>
                          <div class="sticiyDiv ">Аванс </div>
                      </td>
                      <td>
                          <div class="sticiyDiv vertical-rl-css">подоходный налог</div>
                      </td>
                      <td>
                          <div class="sticiyDiv vertical-rl-css">Умумий сакланган </div>
                      </td>
                      <td>
                          <div class="sticiyDiv vertical-rl-css ">Туланадиган <br> маош микдор </div>
                      </td>
                  </tr>
              </thead>
              <tbody  v-for="(department, department_index) in list" :key="'index-' + department_index" v-if="list&&list.length!=0&&department&&department.staffs&&department.staffs.length !=0">
                  <tr  class="department_style">
                    <td  :colspan="19+range.first" class="bg-g">
                        <span class="department_style_span">{{department.name}}</span>
                    </td>
                  </tr>
                  <tr  v-for="(staff, index) in department.staffs"  :key="'index-' + index" >
                    <td class="sticiy_22"> <div class="sticiyDiv"><b>{{department_index+1}}.{{index+1}}</b></div></td>
                    <td class="sticiy_22"> <div class="sticiyDiv" @dblclick="profile(staff)">{{ staff.id }}</div></td>
                    <td class="sticiy_22 name_user"> <div class="sticiyDiv" @dblclick="profile(staff)"><b>{{ staff.name + " " + staff.last_name +" "+staff.middle_name }}</b> </div></td>
                    <td><div class="sticiyDiv">{{Number(staff.oficial_salary).toLocaleString("fi-FI")}}</div></td>
                    <td><div class="sticiyDiv">{{staff.position ? staff.position.name : "" }}</div></td>
                    <td><div class="sticiyDiv">{{staff.work_time&&staff.work_time.limit?staff.work_time.limit.work_day_count:0}}</div></td>
                    <td><div class="sticiyDiv">{{staff.work_time&&staff.work_time.limit?generateTime(staff.work_time.limit.average_work_time):0}}  </div></td>
                    <td><div class="sticiyDiv">{{workTimeFormat(staff)}}</div></td>
                    <td><div class="sticiyDiv">{{staff.monthly_reports&&staff.monthly_reports[0]?generateTime(staff.monthly_reports[0].total_working_hour):0}}</div></td>
                    <td class="tabelColor4" @dblclick="showWorkInfoList(staff.id, filterForm.date)"> 
                      <div class="sticiyDiv ">{{ staff?generateTime(staff.online_works+staff.additional_works):0}}</div> 
                    </td>
                    <td v-for="(report, index) in staff.daily_reports" :key="'index-' + index" v-if="report"  
                          @dblclick="showInfoList(report.id, staff.id, report.date)" 
                          :class="getClass(report, staff.work_time.limit.average_work_time)">
                        <span class="workly_hour" :title="'Рабочее время '+(report.date)" v-if="report.total_working_hour !=0">  
                          {{generateTime(report.total_working_hour)}}
                        </span>
                        <span v-if="(report.online_work_hour || report.additional_work_hour)" :title="'Дистанционная или Дополнительная работа '+(report.date)">
                            {{generateTime(report.online_work_hour+report.additional_work_hour)}}
                        </span >
                        <span v-if="(report.total_working_hour==0)&&((report.online_work_hour+report.additional_work_hour)==0)">
                          0
                        </span>
                    </td>
                    <td v-if="staff.daily_reports&&staff.daily_reports.length==0" class="day-not-found" v-for="(day, index) in range.first" :key="'first' + index" :title="staff.status?staff.status.name:''">
                      <div class="sticiyDiv">-</div>
                    </td>
                    <td><div class="sticiyDiv">{{Number(staff.per_hour).toLocaleString("fi-FI")}}</div></td>
                    <td><div class="sticiyDiv">{{ Number(staff.salary).toLocaleString("fi-FI")}}</div></td>
                    <td><div class="sticiyDiv">-</div></td>
                    <td><div class="sticiyDiv">{{ Number(staff.for_paying_salary).toLocaleString("fi-FI")}}</div></td>
                    <td><div class="sticiyDiv">{{Number(staff.penaltiy_sum).toLocaleString("fi-FI")}}</div></td>
                    <td><div class="sticiyDiv">-</div></td>
                    <td><div class="sticiyDiv">{{staff.income_tax?staff.income_tax+'%':'-'}}</div></td>
                    <td><div class="sticiyDiv">-</div></td>
                    <td><div class="sticiyDiv">-</div></td>
                  </tr>
              </tbody>
          </table>
      </div>
      <div class="my___pagination right-re">
          <crm-pagination
              @c-change="updatePagination"
              :class="mode ? 'pagination__day' : 'pagination__night'"
              :pagination="pagination"
          ></crm-pagination>
      </div>
    </div>
    <el-drawer
      title="I'm outer Drawer"
      :visible.sync="showInfoModal"
      size="40%"
      :with-header="false"
      ref="show-info"
      @opened="drawerOpened('show-info')"
      @closed="drawerClosed('show-info')"
      >
      <div>
        <show-info
          @closeInfoModal="closeInfoModal"
          :child="child"
          ref="show-info"
        ></show-info>
      </div>
    </el-drawer>
    <el-drawer
      title="I'm outer Drawer"
      :visible.sync="showWorkInfoModal"
      size="40%"
      :with-header="false"
      ref="show-info"
      @opened="drawerOpened('show-work-info')"
      @closed="drawerClosed('show-work-info')"
      >
      <div>
        <show-work-info
          @closeWorkInfoModal="closeWorkInfoModal"
          :child="child"
          ref="show-work-info"
        ></show-work-info>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
import selectBranch from "@/components/filters/inventory/select-branch";
import showInfo from "./components/show-info.vue";
import showWorkInfo from "./components/show-works-info.vue";
import moment from "moment";
export default {
  name: 'HrQalampirFrontendIndex',
  mixins: [list],
  components: {
    selectBranch,
    showWorkInfo,
    showInfo
  },
  data() {
    return {
      drawerCreate: false,
      drawerShow: false,
      drawerUpdate: false,
      showInfoModal: false,
      showWorkInfoModal: false,
      filterForm: {},
      loadingData: false,
      reloadList: false,
      loadingComments: false,
      isEvenOrOdd: "odd",
      excelStyles:{},
      range: { first: 0, last: 0 },
      child:{},
      excelData:[],
      filterForm:{},
      pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e7;
          }
      } 
    };
  },
  created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
    this.triggerChanges(this.filterForm.date);
    this.debouncedFetchData = _.debounce(this.fetchData, 500);
  },
  watch:{
    list:{
          handler: async function (newVal, oldVal) {
             this.updateExcel(newVal);

          },
          deep: true,
          immediate: true
        },
    filterForm:{
          handler: async function (newVal, oldVal) {
            this.triggerChanges(newVal.date)
             this.controlExcelData();
          },
          deep: true,
          immediate: true
        },
  },
  computed: {
    ...mapGetters({
      list: "attendanceReport/list",
      columns: "attendanceReport/columns",
      pagination: "attendanceReport/pagination",
      statues: "attendanceReport/statues",
      filter: "attendanceReport/filter",
      sort: "attendanceReport/sort",
      mode: "MODE"
    }),
    actions: function () {
      return [];
    },
    
  },
  methods: {
    ...mapActions({
      updateList: "attendanceReport/newIndex",
      setPagination: "attendanceReport/setPagination",
      updateSort: "attendanceReport/updateSort",
      updateFilter: "attendanceReport/updateFilter",
      updateColumn: "attendanceReport/updateColumn",
      updatePagination: "attendanceReport/updatePagination",
      show: "attendanceReport/show",
      // empty: "attendanceReport/empty",
      delete: "attendanceReport/destroy",
      refreshData: "attendanceReport/refreshData",
    }),
    workTimeFormat:function(staff){
      if (staff.is_free) {
        if (staff.monthly_work_hour) {
          return  this.generateTime(staff.monthly_work_hour);
        }
        else{
          return  '0';
        }
      }
      else{
        if (staff.work_time&&staff.work_time.limit) {
          return  this.generateTime(staff.work_time.limit.mounth_limit);
        }
        else{
          return  '0';
        }
      }
      
    },
    controlExcelData() {
      this.excel_fields = {
          '~': 'counter',
          'т/р': 'id',
          'Ф.И.Ш.': 'name',
          'оклад': 'oficial_salary',
          'Лавозим': 'position',
          'иш куни': 'work_day_count',
          'иш соати': 'average_work_time',
          'стандарт<br/>иш вакти': 'mounth_limit',
          'Общий<br/> рабочий час': 'work_time',
          '<div >Дистанционная или<br/> Дополнительная</div>': 'online_and_additional_works',
        };
        for (let index = 1; index <= this.range.first; index++) {
          this.excel_fields[this.getDay(index, this.filterForm.date)+' '+moment(this.filterForm.date,"DD.MM.YYYY").locale('ru').format("YYYY")]='day_'+index
        }
        this.excel_fields['соат нархи ']='per_hour';
        this.excel_fields['Норма буйича<br/> иш хаки']='salary';
        this.excel_fields['Жами<br/> туланиш керак']='for_paying_salary';
        this.excel_fields['подоходный налог']='income_tax';
        this.excel_fields['Жарима']='penaltiy_sum';
     },
   async updateExcel(newVal){
      if (newVal) {
        var alllArrayValue = [];
        console.log('list', newVal)
        newVal.forEach((depart, depart_index) => {
          let depa_index =depart_index+1;
          var department=[];
          // department['counter']='<b>'+(depa_index)+'</b>';
          department['name']=`<strong style="font-size:15px">${depa_index}. ${depart.name}</strong>`;
          alllArrayValue.push(department);
          if (depart.staffs&&depart.staffs.length) {
            depart.staffs.forEach((staff, index) => {
              var date=[];
              date['counter']=this.styleCenter(`<b>${String(depa_index)}. ${String(index+1)}</b>`);
              date['name']=this.styleCenter(`<b>${staff.name} ${staff.last_name}<br/> ${staff.middle_name}</b>`);
              date['id']=this.styleCenter(staff.id);
              date['oficial_salary']=this.styleCenter(Number(staff.oficial_salary).toLocaleString("fi-FI"));
              date['online_and_additional_works']='<div style="text-align:center; color: #d36ffb;" ><p style="text-align:center; vertical-align: middle; display:inline-block; margin:0;">'+this.generateTime(staff.online_works+staff.additional_works)+'</p></div>';
              date['penaltiy_sum']=this.styleCenter(Number(staff.penaltiy_sum).toLocaleString("fi-FI"));
              date['per_hour']=this.styleCenter(staff.per_hour);
              date['position']=this.styleCenter(staff.position.name);
              date['salary']=this.styleCenter(Number(staff.salary).toLocaleString("fi-FI"));
              date['for_paying_salary']=this.styleCenter(Number(staff.for_paying_salary).toLocaleString("fi-FI"));
              date['income_tax']=this.styleCenter(staff.income_tax);
              date['un_oficial_salary']=this.styleCenter(Number(staff.un_oficial_salary).toLocaleString("fi-FI"));
              date['average_work_time']=this.styleCenter(this.generateTime(staff.work_time.limit.average_work_time));
              date['work_day_count']=this.styleCenter(staff.work_time.limit.work_day_count);
              date['mounth_limit']= this.styleCenter(this.generateTime(staff.work_time.limit.mounth_limit));
              date['work_time']=this.styleCenter(this.generateTime(staff.work_time.work_time));
              if (staff.daily_reports&&staff.daily_reports.length) {
                staff.daily_reports.forEach((daily_report, daily_report_index) => {
                 date['day_'+(daily_report_index+1)]=this.styleCenter(this.generateTime(daily_report.total_working_hour));
                });
              }
              alllArrayValue.push(date);
          });
          }
        });
        this.excelData =alllArrayValue;
      }
    },
    styleCenter(text){
      return '<div style="text-align:center;"><p style="text-align:center; vertical-align: middle; display:inline-block; margin:0;">'+text+'</p></div>';
    },
    generateTime(val){
      if ((val - parseInt(val))) {
        return parseInt(val) + i18n.t('message.hour') +' '+ ((val - parseInt(val)) *60).toFixed(0)+i18n.t('message.minut');
      }
      else{
        return  parseInt(val) + i18n.t('message.hour');
      }
    },
    rangeFunction: function (start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    onlineWorks(arra){
      var text ='Online Works';
      arra.forEach(element => {
        text +="\n Vaqti: "+element.from_time+' - '+element.to_time;
      });
      return  text;
    },
    triggerChanges(date) {
      let lastDayOfMonth = moment(date, "DD.MM.YYYY")
        .endOf("month")
        .format("DD");
      lastDayOfMonth = parseInt(lastDayOfMonth);
      // if (lastDayOfMonth % 2 == 0) {
      //   this.range.first = lastDayOfMonth 
      //   return (this.isEvenOrOdd = "even");
      // }

      this.range.first = parseInt(lastDayOfMonth);

     
    // this.range.first.forEach((element, index) => {
    //     console.log(element, index)
    // });
      // this.range.last = lastDayOfMonth;
      // console.log(this.range);
      // this.isEvenOrOdd = "odd";
    },
    closeInfoModal(val) {
      this.showInfoModal = val;
    },
    closeWorkInfoModal(val) {
      this.showWorkInfoModal = val;
    },
    showInfoList(id, staff_id, date) {
      this.child.id = id;
      this.child.staff_id = staff_id;
      this.child.date = date;
      this.showInfoModal = true;
    },
    showWorkInfoList(id, date) {
      this.child.id = id;
      this.child.date = date;
      this.showWorkInfoModal = true;
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    },
    profile(model) {
      let route = this.$router.resolve({
        name: "profile",
        params: {
          id: model.id,
        },
      });

      window.open(route.href, "_blank");

      //    OLD CODE
      //   this.$router.push({
      //     name: "profile",
      //     params: { id: model.id },
      //   });
    },
    getClass(report, average_work_time){
      if (report.is_busi) {
        return 'business_trip'
      }
      else{
        if (report.is_manual) {
          return 'tabelColor3'
        }
        else{
          if (report.is_wikend_day) {
            return 'wikend-day'
          }
          else{
             if (report.in_vacation) {
              return 'in_vacation';
             } else {
              if (report.is_not_working_day) {
                return 'day-off';
              }
              else{
                if (report.total_working_hour>0) {
                  if (report.total_working_hour < average_work_time) {
                    return 'tabelColor1';
                  }
                  else{
                    return 'tabelColor5';
                  }
                }
                else{
                  if (report.total_working_hour==0&&(report.online_work_hour || report.additional_work_hour)) {
                    return 'tabelColor4';
                  }
                  else{
                    return (report.total_working_hour==0) && (report.state=='OPENED')?'tabelColor':'tabelColor2';
                  }
                }
              }
             }
            }
        }
      }
    },
    getDay(day, date){
      return day+' - '+ moment(date,"DD.MM.YYYY").locale('ru').format("MMMM");
    }
  },
};
</script>

<style lang="scss" scoped>
.vertical-align-c th, .vertical-align-c td{
  vertical-align: middle !important;
  text-align: center !important;
  font-size: 12px !important;
  padding: 5px 4px;
}
.tableScrol {
margin-top: -1px;
width: 100%;
/* overflow: hidden; */
height: calc(100vh - 222px);
overflow-y: scroll;
}
.rotate{
  transform: rotate(90deg);
}
.tableScrol table {
width: 4500px;
}
.coKvadrat{
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.coKvadrat span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  margin-right: 2px;
}
.tabelColor0 {
background: #c3c3c3;
}
.tabelColor1 {
background: #a3d5f3;
}
.tabelColor1:hover {
background: #87cdf7;;
}
.tabelColor2 {
background: #f39393;
}
.tabelColor2:hover{
  background: #ff7878;
}
.tabelColor5 {
  background: #9edb80;
}
.tabelColor5:hover {
  background: #93e56b;
}
.in_vacation{
  background: #bcbef7;
}
.NO_CHECK_IN_AND_OUT {
background: #f39393;
}
.tabelColor3 {
background: #ff8100;
}
.tabelColor3:hover {
background: #e37300;
}
.wikend-day{
  background: #bd8c8c;
}
.wikend-day:hover{
  background: #906767;
}

.business_trip{
  background: #0d67c9;
  color: #fff;
}
.business_trip:hover{
  background: #0321ca;
}
.day-off {
background: hsl(66, 80%, 76%);
}
.day-off:hover{
    background: #cedb58;
}
.day-not-found{
background: #cdccccba;

}
.tabelColor4 {
background: #d791f3;
}
.tabelColor4:hover {
background: #d36ffb;
}
.vertical-rl-css{
  height: 115px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  margin: 0 auto;
  z-index: 2;
  position: relative;
}
.w-t-150,
.w-t-100,
.sticiyDiv{
  text-align: center;
}
.w-t-150{
  width: 150px;
}
.w-t-100{
  width: 100px;
}
.sticiy_22{
  position: sticky;
  left: 0px;
  z-index: 2;
}
.sticky-thead{
  top: 0;
  z-index: 3;
  position: sticky;
  left: 0px;
  th,
  td{
      margin-bottom: -2px;
  }
}
.sticky-thead, .sticiy_22, .bg-g{
  background: #cbe0f7 !important;
}
.bg-g{
  font-weight: bold;
}
.vertical-align-c.table-my-code td, .vertical-align-c.table-my-code th{
  border: 1px solid #c9c9c9 !important;
}
.top-title-mre{
  padding: 9px;
  border-bottom: 1px solid #c9c9c9 !important;
  margin-bottom: -1px;
}
.vertical-align-c b{
  font-weight: 700;   
}
.z-i-4{
  z-index: 4;
}
.over{
display: flex;
justify-content: center;
align-items: center;
/* padding: 2px;*/
}
.over >div{
padding: 3px 3px;
width: 50%;

}
.workly_online_or_additional.yellow{
background: #3e8bc7;
color: #e6e6e6;
}
.month_day{
  min-width: 70px;
}
.table-my-code tbody>tr:hover,
thead>tr:hover {
  background: #cbe0f7 !important;
}
// .table-my-code tbody>tr:hover td:hover {
//   background: #a3d5f3;
 
// }

.table-my-code thead>tr td div, .table-my-code thead>tr td span,
.table-my-code tbody>tr:hover td div, .table-my-code tbody>tr:hover td span{
  font-weight: bold;
}
.table-my-code tbody>tr:hover td:hover div, .table-my-code tbody>tr:hover td:hover span{
  cursor: pointer;
}
.right-re{
  margin-top: -22px !important;
  padding-bottom: 3px;
  margin-bottom: -17px !important;
  display: flex;
  justify-content: start !important;
  margin-left: 10px;
}
.table-my-code tbody>tr:hover{
  border-bottom: 2px solid #b41f1f; 
}
.name_user{
  background: #67c23a !important;
}
.department_style td{
  background: #b2cde9 !important;
  text-align: left !important
}
.department_style:hover{
  border-bottom:none  !important;
}
.department_style_span{
  z-index: 2;
  position: sticky;
  left: 50%;
  font-weight: 700;
}
</style>
